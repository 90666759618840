import 'jquery-ui/ui/widgets/autocomplete.js';

//Autocomplete do campo de busca da home
// var autocompleteOptions = [
//   {
//     value: "slug-verbete-Anita-Malfati",
//     label: "Anita Malfati",
//     desc: "pessoa",
//   },
//   {
//     value: "slug-verbete-Anita-Simis",
//     label: "Anita Simis",
//     desc: "pessoa",
//   },
//   {
//     value: "slug-verbete-Anita-Sousa",
//     label: "Anita Sousa",
//     desc: "pessoa",
//   },
//   {
//     value: "slug-verbete-Maria-Anita",
//     label: "Maria Anita",
//     desc: "pessoa",
//   },
//   {
//     value: "slug-verbete-Anita-Bastos",
//     label: "Anita Bastos",
//     desc: "pessoa",
//   },
//   {
//     value: "slug-verbete-Anita-Heloisa",
//     label: "Anita Heloísa",
//     desc: "pessoa",
//   },
//   {
//     value: "slug-verbete-Anita-Mattos",
//     label: "Anita Mattos",
//     desc: "pessoa",
//   },
// ];

document.addEventListener("DOMContentLoaded", function () {
  if ($("#searchTerm").length == 0) return;

  $("#searchTerm").autocomplete({
    source: '/autocomplete',
    minLength: 3,
    select: function (event, ui) {
      window.location.pathname = ui.item.target;
    },
    search: function (event, ui) {
      document.body.style.cursor = 'progress';
    },
    response: function (event, ui) {
      document.body.style.cursor = 'default';
    }
  }).autocomplete("instance")._renderItem = function (ul, item) {
    let li = $('<li class="search-link">');
    let div = $("<div><span class='item-label'>" + item.label + "</span><br><span class='item-desc'>" + item.description + "</span></div>")

    div.attr('target', item.target);
    li.append(div)

    return li.appendTo(ul);
  };
});
